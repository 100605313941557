import { PaletteOptions } from '@mui/material';

// Mifianza colors
export const MifianzaColors: PaletteOptions = {
  primary: { main: '#20836e', light: '#def7f2', dark: '#279F85' },
  secondary: { main: '#f95e21', light: '#FDC5AF', dark: '#ec2300' },
};

// Mili colors
export const MiliColors: PaletteOptions = {
  primary: {
    main: '#1C6301',
    dark: '#143802',
    light: '#D9F0CE',
  },
  secondary: {
    // main: '#FF4400',
    main: '#FF6800',
    dark: '#FF4400',
    light: '#ffc255',
  },
  info: {
    main: '#f59121',
    dark: '#c63703',
    light: '#ffc255',
  },
};

// Mili colors
export const MiliColors2: PaletteOptions = {
  primary: { main: '#00665a', dark: '#003d36', light: '#c3e9e9' },
  secondary: { main: '#E79836', dark: '#BF6900', light: '#F4E2CD' },
};

// Finnvo colors
export const FinnvoColors: PaletteOptions = {
  primary: { main: '#00AF98', dark: '#2b5a55', light: '#b1ddd9' },
  secondary: { main: '#55918b', dark: '#00231f', light: '#D6F4FF' },
};

// Bancaliv colors
export const BancalivColors: PaletteOptions = {
  primary: { main: '#a1005d', dark: '#5f1441', light: '#F4C1E1' },
  secondary: { main: '#79114A', dark: '#5f1441', light: '#b493a7' },
};

// Creditoo colors
export const CreditooColors: PaletteOptions = {
  primary: { main: '#0097D7', dark: '#00648F', light: '#D6F3FF' },
  secondary: { main: '#FFB23F', dark: '#F59121', light: '#FFE7C2' },
};

// Fie colors
export const FieColors: PaletteOptions = {
  primary: { main: '#0071C0', dark: '#00477A', light: '#D6EEFF' },
  secondary: { main: '#FF6F3B', dark: '#FF4400', light: '#FFD2C2' },
};

// Fibanca colors
export const FibancaColors: PaletteOptions = {
  primary: { main: '#FFCE47', dark: '#E0A500', light: '#FFEFC2' },
  secondary: { main: '#101C42', dark: '#080E21', light: '#CED4F3' },
};

// Fondo colors
export const FondoColors: PaletteOptions = {
  primary: { main: '#006BB6', dark: '#00477A', light: '#C2E5FF' },
  secondary: { main: '#FFB23F', dark: '#F59300', light: '#FFDEAD' },
};

// TuSolucion colors
export const TuSolucionColors: PaletteOptions = {
  primary: { main: '#2DAC67', dark: '#1D7243', light: '#CEF3DD' },
  secondary: { main: '#1571BA', dark: '#105893', light: '#C8E3F9' },
};

// Credipress colors
export const CredipressColors: PaletteOptions = {
  primary: { main: '#4147AF', dark: '#272A68', light: '#D2D4EE' },
  secondary: { main: '#FFB23F', dark: '#F59121', light: '#FFD699' },
};

// Liberate colors
export const LiberateColors: PaletteOptions = {
  primary: { main: '#0B2442', dark: '#061323', light: '#DCE9F9' },
  secondary: { main: '#FFB23F', dark: '#CC7A00', light: '#FFD699' },
};

// Nauw colors
export const NauwColors: PaletteOptions = {
  primary: { main: '#56AC47', dark: '#206F29', light: '#E5F3E2' },
  secondary: { main: '#1571BA', dark: '#0E4D81', light: '#C8E2F9' },
};

// Lenddi colors
export const LenddiColors: PaletteOptions = {
  primary: { main: '#014C2D', dark: '#012818', light: '#D7FEEE' },
  secondary: { main: '#32FAA5', dark: '#049F5F', light: '#C3FDE5' },
};

// Gym colors
export const GymColors: PaletteOptions = {
  primary: { main: '#111ABF', dark: '#171a4b', light: '#cdddff' },
  secondary: { main: '#13b3f2', dark: '#1d7464', light: '#00ffd0' },
};

// Credinver colors
export const CredinverColors: PaletteOptions = {
  primary: { main: '#627732', dark: '#314C45', light: '#D0DBBB' },
  secondary: { main: '#E58D35', dark: '#EC7234', light: '#FC9854' },
};

// Inversiones colors
export const InversionesColors: PaletteOptions = {
  primary: { main: '#F9BA2F', light: '#FFF3C3', dark: '#B7841B' },
  secondary: { main: '#D35400', light: '#E67E22', dark: '#A84300' },
};

// Freend colors
export const FreendColors: PaletteOptions = {
  primary: { main: '#1C0147', light: '#9E7DD3', dark: '#6732BD' },
  secondary: { main: '#36136E', light: '#3C1E6C', dark: '#6732BD' },
};
// MaxCredits colors
export const MaxcreditColors: PaletteOptions = {
  primary: { main: '#002449', dark: '#031126', light: '#8DD7F7' },
  secondary: { main: '#E5731F', dark: '#E05B27', light: '#F9CDD5' },
};
// Sofi colors
export const SofiColors: PaletteOptions = {
  primary: { main: '#5941E0', dark: '#0450F6', light: '#F6F4F9' },
  secondary: { main: '#39008A', dark: '#D955F9', light: '#9B4DF7' },
};
// Zali colors
export const ZaliColors: PaletteOptions = {
  primary: { main: '#003478', dark: '#0E1A58', light: '#D9E9FF' },
  secondary: { main: '#AFAF45', dark: '#DFDF00', light: '#eded6e' },
};
// Libramoneda colors
export const LibramonedaColors: PaletteOptions = {
  primary: { main: '#1e3467', dark: '#1b2f5e', light: '#C8E2F9' },
  secondary: { main: '#4eb9b4', dark: '#3b8b87', light: '#C8E2F9' },
};
// QuickBank colors
export const QuickBankColors: PaletteOptions = {
  primary: { main: '#1f1f1f', dark: '#101010', light: '#b3b3b3' },
  secondary: { main: '#A67A49', dark: '#c7754d', light: '#F3EBC2' },
};
// Finanzas colors
export const FinanzasColors: PaletteOptions = {
  primary: { main: '#1C1D3C', dark: '#180F2B', light: '#8481AE' },
  secondary: { main: '#FF6C1A', dark: '#ED5600', light: '#FFB78E' },
};

// Fembisol colors
export const FembisolColors: PaletteOptions = {
  primary: { main: '#338F40', dark: '#265F2E', light: '#D8FFC5' },
  secondary: { main: '#338F40', dark: '#265F2E', light: '#D8FFC5' },
};

// CreceValor colors
export const CreceValorColors: PaletteOptions = {
  primary: { main: '#CB1D49', dark: '#9D0F32', light: '#FFE3EC' },
  secondary: { main: '#7C0044', dark: '#4A0029', light: '#cc98b0' },
};

// SuValor colors
export const SuValorColors: PaletteOptions = {
  primary: { main: '#006068', dark: '#003D48', light: '#c3e9e9' },
  secondary: { main: '#F05349', dark: '#D62B1F', light: '#805a63' },
};

// Finzap colors
export const FinzapColors: PaletteOptions = {
  primary: { main: '#677416', dark: '#304500', light: '#F3FFC3' },
  secondary: { main: '#8BBE37', dark: '#65901E', light: '#E2FFB2' },
};

// Procord colors
export const ProcordColors: PaletteOptions = {
  primary: { main: '#000D56', dark: '#010938', light: '#DBE1FF' },
  secondary: { main: '#0077F2', dark: '#006AD7', light: '#6EB3FA' },
};
